import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Swap',
    icon: 'HomeIcon',
    href: '/swap',
  },
  {
    label: 'PIX',
    icon: 'IfoIcon',
    href: '#',
  },
  {
    label: 'Bridge',
    icon: 'IfoIcon',
    href: '#',
  },
  {
    label: 'Stake',
    icon: 'IfoIcon',
    href: '#',
  },
  {
    label: 'Vote',
    icon: 'IfoIcon',
    href: '#',
  },
  {
    label: 'Ranking',
    icon: 'IfoIcon',
    href: '#',
  }
]

export default config
